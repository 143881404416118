import React, {useState, useEffect} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import DatePicker from "react-datepicker";
import {
    adjustDateToBrowserTimeZone, adjustDateToServerTimeZone, calculateDaysDifference,
    formatDateTimeToString,
    formatDateToAmericanFormat,
    formatDateToString, getOnlyDate, UserPermission
} from "../../utils/utils";
import {getReceivableById, userHasPermission} from "../../utils/requestUtils";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import ModalDiv from "../../ModalDiv";
import SaleViewer from "../sale/SaleViewer";
import PaymentMethodValueSelect from "../paymentMehod/PaymentMethodValueSelect";
import Loading from "../../core/Loading";

const apiUrl = Config.apiUrl;
const ReceivableList = (props) => {
    const {onEditButtonClick, onNewButtonClick} = props;
    const [receivables, setReceivables] = useState([]);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [status, setStatus] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalValue, setTotalValue] = useState(0);
    const navigate = useNavigate();
    const [saleIdToBeShowed, setSaleIdToBeShowed] = useState(undefined);
    const [receivableToBeShowedInPaymentMethodValueSelect, setReceivableToBeShowedInPaymentMethodValueSelect] = useState(undefined);

    const [hasListPermission, setListPermission] = useState(undefined);
    const [hasEditPermission, setEditPermission] = useState(undefined);
    const [hasCreatePermission, setCreatePermission] = useState(undefined);
    const [hasRemovePermission, setRemovePermission] = useState(undefined);
    const [hasReversePermission, setReversePermission] = useState(undefined);

    const [hasShowTotalPermission, setShowTotalPermission] = useState(undefined);

    async function makeQuery() {
        try {

            const newStartDate = startDate ? startDate : new Date(1900, 0, 1);
            const newEndDate = endDate ? endDate : new Date(2100, 0, 1);
            const url = `${apiUrl}/api/receivables/queryByPaymentDate?startDate=${formatDateToAmericanFormat(newStartDate)}&endDate=${formatDateToAmericanFormat(newEndDate)}&status=${status}&searchTerm=${searchTerm}`;
            const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
            setReceivables(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {

        }
    }

    useEffect(() => {
        if (hasListPermission === false) {
            navigate('/unauthorizedaccess');
        }
    }, [hasListPermission]);

    useEffect(() => {
        userHasPermission(UserPermission.SHOW_TOTAL_RECEIVABLE_LIST).then(userHasPermission => setShowTotalPermission(userHasPermission));
        userHasPermission(UserPermission.EDIT_RECEIVABLE).then(userHasPermission => setEditPermission(userHasPermission));
        userHasPermission(UserPermission.CREATE_RECEIVABLE).then(userHasPermission => setCreatePermission(userHasPermission));
        userHasPermission(UserPermission.LIST_RECEIVABLE).then(userHasPermission => setListPermission(userHasPermission));
        userHasPermission(UserPermission.REMOVE_RECEIVABLE).then(userHasPermission => setRemovePermission(userHasPermission));
        userHasPermission(UserPermission.REVERSE_RECEIVABLE).then(userHasPermission => setReversePermission(userHasPermission));

        makeQuery();
    }, []); // Empty dependency array ensures that useEffect runs only once, similar to componentDidMount

    useEffect(() => {
        setTotalValue(receivables.reduce((acc, obj) => acc + obj.value, 0));
    }, [receivables]);

    async function removeReceivable(id) {
        try {
            const url = apiUrl + '/api/receivables/' + id;
            await makeRequest(url, 'DELETE', {'Useruuid': Cookies.get('userUUID')});
            await makeQuery();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleQuery = async (e) => {
        await makeQuery();
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };
    const onPayClick = async (id) => {
        const receivable = await getReceivableById(id);
        if (!window.confirm(`Deseja realmente ${!receivable.paymentDate ? "Baixar" : "Estornar"}?`)) {
            return;
        }
        if (receivable.paymentDate) {
            receivable.paymentDate = undefined;
            receivable.status = 0;
        } else {
            receivable.paymentDate = getOnlyDate(new Date());
            receivable.status = 1;
        }

        const url = apiUrl + '/api/receivables';
        await makeRequest(url, 'PUT', {
            'Content-Type': 'application/json',
            'Useruuid': Cookies.get('userUUID')
        }, JSON.stringify(receivable));

        await makeQuery();
    };

    function getBackgroundColor(receivable) {
        const currentDate = getOnlyDate(new Date());
        const dueDate = getOnlyDate(adjustDateToServerTimeZone(new Date(receivable.dueDate)));
        const errorColor = 'var(--error-color)';
        const warningColor = 'var(--warning-color)';
        const normalColor = 'var(--normal-color)';
        if (receivable.status === 0) {
            if (dueDate < currentDate)
                return errorColor;
            if (calculateDaysDifference(dueDate, currentDate) === 0) {
                return warningColor;
            }
        }
        return normalColor;
    }

    const handleInputChange = (e) => {
        const string = e.target.value;
        setSearchTerm(string);
    };


    const [sortConfig, setSortConfig] = useState({key: 'dueDate', direction: 'ascending'});

    const [sortedSales, setSortedSales] = useState([]);

    useEffect(() => {
        setSortedSales([...receivables].sort((a, b) => {
            const aValue = sortConfig.key.includes('.')
                ? (a[sortConfig.key.split('.')[0]]?.[sortConfig.key.split('.')[1]] ?? '')
                : (a[sortConfig.key] ?? '');
            const bValue = sortConfig.key.includes('.')
                ? (b[sortConfig.key.split('.')[0]]?.[sortConfig.key.split('.')[1]] ?? '')
                : (b[sortConfig.key] ?? '');

            if (aValue < bValue) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        }));
    }, [receivables, sortConfig]);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return '';
    };

    if (hasListPermission === undefined
        || hasEditPermission === undefined
        || hasCreatePermission === undefined
        || hasRemovePermission === undefined
        || hasReversePermission === undefined
    ) {
        return <Loading></Loading>;
    }

    return (
        <>
            {receivableToBeShowedInPaymentMethodValueSelect && (
                <PaymentMethodValueSelect onClose={() => setReceivableToBeShowedInPaymentMethodValueSelect(undefined)}
                                          allowChangeValue={false}
                                          allowChangeDueDate={false}
                                          forceDueDate={new Date()}
                                          paymentMethods={[{
                                              paymentMethod: receivableToBeShowedInPaymentMethodValueSelect.paymentMethod,
                                              value: receivableToBeShowedInPaymentMethodValueSelect.value,
                                              paid: receivableToBeShowedInPaymentMethodValueSelect.status === 1,
                                              splits: receivableToBeShowedInPaymentMethodValueSelect.receivableSplits ? receivableToBeShowedInPaymentMethodValueSelect.receivableSplits.length : 1,
                                              dueDate: receivableToBeShowedInPaymentMethodValueSelect.dueDate,
                                          }]}
                                          totalValue={receivableToBeShowedInPaymentMethodValueSelect.value}
                                          onConfirm={async (paymentMethodValues) => {
                                              if (!paymentMethodValues || paymentMethodValues.length === 0) {
                                                  return;
                                              }
                                              const paymentMethodValue = paymentMethodValues[0];
                                              if (!paymentMethodValue.paid) {
                                                  alert("Nenhum baixa foi efetuada. Marque como 'pago' antes de confirmar.");
                                                  return;
                                              }
                                              const receivable = await getReceivableById(receivableToBeShowedInPaymentMethodValueSelect.id);
                                              receivable.paymentMethod = paymentMethodValue.paymentMethod;
                                              receivable.value = paymentMethodValue.value;
                                              receivable.dueDate = paymentMethodValue.dueDate;
                                              receivable.status = 1;
                                              receivable.paymentDate = new Date();
                                              const url = apiUrl + '/api/receivables';
                                              await makeRequest(url, 'PUT', {
                                                  'Content-Type': 'application/json',
                                                  'Useruuid': Cookies.get('userUUID')
                                              }, JSON.stringify(receivable));
                                              if (receivableToBeShowedInPaymentMethodValueSelect.splits !== paymentMethodValue.splits) {
                                                  const url = apiUrl + '/api/receivables/' + receivable.id + '/updatesplits/' + paymentMethodValue.splits;
                                                  await makeRequest(url, 'PUT', {
                                                      'Content-Type': 'application/json',
                                                      'Useruuid': Cookies.get('userUUID')
                                                  }, JSON.stringify(receivable));
                                              }
                                              setReceivableToBeShowedInPaymentMethodValueSelect(undefined)
                                              await makeQuery();
                                          }}/>)}

            {saleIdToBeShowed && <ModalDiv closeModal={() => setSaleIdToBeShowed(undefined)}>

                <SaleViewer saleId={saleIdToBeShowed}>
                </SaleViewer>
            </ModalDiv>}
            <div style={{display: "flex", alignItems: "center", gap: '10px'}}>
                <div className="header-label">Consulta de Recebimentos</div>
                {hasCreatePermission &&
                    <button className={"new-button"} onClick={() => navigate('/receivable')}>Novo</button>
                }
            </div>
            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%'}}>

                <div style={{display: 'flex'}}>
                    <div style={{marginTop: '5px', alignItems: 'center'}}>
                        Nome/Serviço
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <input type="text" value={searchTerm} onChange={handleInputChange}/>
                        </div>
                    </div>
                    <div style={{marginTop: '5px', alignItems: 'center'}}>
                        Data de Vencimento:
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <DatePicker
                                className="datepicker"
                                selected={startDate}
                                onChange={handleStartDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />

                            até
                            <DatePicker
                                className="datepicker"
                                selected={endDate}
                                onChange={handleEndDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />


                            <select value={status} onChange={(e) => setStatus(parseInt(e.target.value))}>
                                <option key={-1} value={-1}>Todos</option>
                                <option key={0} value={0}>Pendente</option>
                                <option key={1} value={1}>Pago</option>
                            </select>
                            <button onClick={handleQuery}>Consultar</button>


                        </div>

                    </div>

                </div>
            </div>

            <table style={{minWidth: '1100px', height: '100%'}}>

                <tr>
                    <th style={{cursor: "pointer", minWidth: "100px"}} onClick={() => handleSort('customerName')}>
                        {getSortIcon('customerName')} Cliente
                    </th>
                    <th style={{cursor: "pointer", minWidth: "100px"}} onClick={() => handleSort('description')}>
                        {getSortIcon('description')} Descrição
                    </th>
                    <th style={{cursor: "pointer", minWidth: "100px"}} onClick={() => handleSort('paymentMethod.name')}>
                        {getSortIcon('paymentMethod.name')} Forma de Pagamento
                    </th>
                    <th style={{cursor: "pointer", minWidth: "100px", textAlign: 'center'}} onClick={() => handleSort('date')}>
                        {getSortIcon('date')} Lançamento
                    </th>
                    <th style={{cursor: "pointer", minWidth: "100px", textAlign: 'center'}} onClick={() => handleSort('dueDate')}>
                        {getSortIcon('dueDate')} Vencimento
                    </th>
                    <th style={{cursor: "pointer", minWidth: "100px", textAlign: 'center'}} onClick={() => handleSort('paymentDate')}>
                        {getSortIcon('paymentDate')} Pagamento
                    </th>
                    <th style={{cursor: "pointer", minWidth: "100px", textAlign: 'right'}} onClick={() => handleSort('value')}>
                        {getSortIcon('value')} Valor
                    </th>
                    <th/>
                </tr>
                <tbody style={{overflowY: 'auto'}}>
                {sortedSales.map((receivable, index) => (


                    <tr style={{backgroundColor: getBackgroundColor(receivable)}}>
                        <td>
                            <div key={index}>{receivable.customerName}</div>
                        </td>
                        <td>
                            <div key={index}>{receivable.description}</div>
                        </td>
                        <td>
                            <div key={index}>{receivable.paymentMethod?.name || ''}</div>
                        </td>
                        <td>
                            <div key={index} style={{textAlign: 'center'}}>{formatDateToString(new Date(receivable.date))}</div>
                        </td>
                        <td>
                            <div
                                key={index} style={{textAlign: 'center'}}>{formatDateToString(adjustDateToServerTimeZone(new Date(receivable.dueDate)))}</div>
                        </td>
                        <td>
                            <div
                                key={index} style={{textAlign: 'center'}}>{receivable.paymentDate ? formatDateToString(new Date(receivable.paymentDate)) : "Pendente"}</div>
                        </td>
                        <td>
                            <div
                                key={index} style={{textAlign: 'right'}}>{receivable.value.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</div>
                        </td>
                        <td>
                            <button
                                disabled={receivable.salesIds.length === 0}
                                title={receivable.salesIds.length === 0 ? "Nenhum servico para mostrar." : ""}

                                onClick={async () => {
                                    await setSaleIdToBeShowed(receivable.salesIds[0]);
                                }}>Ver Serviço
                            </button>
                            {hasEditPermission &&
                                <button onClick={async () => {
                                    await navigate('/receivable?id=' + receivable.id);
                                }}>Editar
                                </button>
                            }

                            {(!receivable.paymentDate || hasReversePermission) &&
                                <button className="finish-button"
                                        style={{width: '75px'}}
                                        onClick={async () => {
                                            if (receivable.status === 1 && receivable.paymentDate) {
                                                await onPayClick(receivable.id);
                                            } else {
                                                setReceivableToBeShowedInPaymentMethodValueSelect(receivable);
                                            }
                                        }}>
                                    {!receivable.paymentDate ? "Baixar" : "Estornar"}
                                </button>
                            }

                            {hasRemovePermission &&
                                <button className="remove-button" onClick={async () => {
                                    // eslint-disable-next-line no-restricted-globals
                                    if (window.confirm('Deseja realmente excluir?')) {
                                        await removeReceivable(receivable.id);
                                    }
                                }}>Excluir
                                </button>
                            }
                        </td>

                    </tr>
                ))}
                </tbody>
                {receivables.length === 0 && (<tfoot>Nenhum item encontrado.</tfoot>)}
            </table>
            {hasShowTotalPermission &&
                <div style={{marginTop: '5px', alignItems: 'center'}}>

                    <div className="total-label">Valor Total: R$ {totalValue.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}</div>
                </div>
            }

        </>
    );
};

export default ReceivableList;
