import React, {useState, useEffect} from 'react';
import '../../css/Select.css';
import {getAllPaymentMethods, getProductAvailabilityPerDate} from "../../utils/requestUtils";
import ModalDiv from "../../ModalDiv";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import ConfirmButton from "../common/ConfirmButton";
import DatePicker from "react-datepicker";
import {
    adjustDateToBrowserTimeZone,
    adjustDateToServerTimeZone,
    calculateDaysDifference, formatDateToString,
    getOnlyDate
} from "../../utils/utils";

const PaymentMethodValueSelect = (props) => {
    const {
        onClose,
        onConfirm,
        totalValue,
        paymentMethods,
        futureDueDate,
        forceDueDate,
        allowChangeValue = true,
        allowChangeDueDate = true,
        allowPaymentWhenPaymentDateDifferentDueDate = false
    } = props;

    const attentionColor = 'var(--attention-color)';
    const normalGreenColor = 'var(--normal-green-color)';
    const normalColor = 'var(--normal-color)';

    const newPaymentMethods = forceDueDate
        ? paymentMethods.map(item => ({ ...item, dueDate: forceDueDate }))
        : paymentMethods;

    const [paymentMethodValues, setPaymentMethodValues] = useState(newPaymentMethods && newPaymentMethods.length > 0 ? newPaymentMethods : [{
        paymentMethod: {id: undefined},
        value: 0,
        dueDate: forceDueDate ? forceDueDate : getOnlyDate(new Date()),
        paid: false
    }]);
    const [typedTotalValue, setTypedTotalValue] = useState(0);
    const [typedTotalValuePaid, setTypedTotalValuePaid] = useState(0);
    const [typedTotalValuePending, setTypedTotalValuePending] = useState(0);
    const [paymentMethods_, setPaymentMethods_] = useState([]);

    useEffect(() => {
        getAllPaymentMethods().then((p) => setPaymentMethods_(p));
        const totalPaymentValueTyped = paymentMethodValues.reduce((acc, item) => acc + item.value, 0);
        if(totalPaymentValueTyped > 0 && totalPaymentValueTyped < totalValue) {
            const dueDate = forceDueDate ? forceDueDate : (futureDueDate ? futureDueDate : getOnlyDate(new Date()));
            setPaymentMethodValues(prevPaymentMethodValues => [
                ...prevPaymentMethodValues,
                {
                    paymentMethod: { id: undefined },
                    value: 0,
                    dueDate: dueDate,
                    paid: getOnlyDate(new Date(dueDate)) <= getOnlyDate(new Date())
                }
            ]);
        }
    }, []);

    function handlePaymentMethodSelected(index, id) {

        const paymentMethod = id ? paymentMethods_.find((p) => p.id === Number(id)) : undefined;

        setPaymentMethodValues((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    paymentMethod: paymentMethod,
                    splits: paymentMethod?.allowSplit ? 1 : 0
                } : item
            )
        );

    }

    function handleValueChange(index, value) {

        setPaymentMethodValues((prevItems) => {


            const updatedItems = prevItems.map((item, i) =>
                i === index
                    ? {
                        ...item,
                        value: parseFloat(value),
                    }
                    : item
            );

            const newPaymentMethodValues = updatedItems.slice(0, index + 1);
            const sumUntilIndex = newPaymentMethodValues.reduce((acc, item) => acc + item.value, 0);

            const difference = Math.round((totalValue - sumUntilIndex) * 100) / 100;
            if (difference > 0) {
                const dueDate = forceDueDate ? forceDueDate : futureDueDate ? futureDueDate : getOnlyDate(new Date());
                newPaymentMethodValues.push({
                    paymentMethod: {id: undefined},
                    value: (difference),
                    dueDate: dueDate,
                    paid: getOnlyDate(new Date(dueDate)) <= getOnlyDate(new Date())
                });
            }
            return newPaymentMethodValues;
        });


    }

    useEffect(() => {
        setTypedTotalValue(paymentMethodValues.reduce((acc, item) => acc + item.value, 0));
        setTypedTotalValuePaid(paymentMethodValues.filter(paymentMethodValue =>  paymentMethodValue.paid).reduce((acc, item) => acc + item.value, 0));
        setTypedTotalValuePending(paymentMethodValues.filter(paymentMethodValue =>  !paymentMethodValue.paid).reduce((acc, item) => acc + item.value, 0));
    }, [paymentMethodValues]);

    function handleSplitsChange(index, value) {
        setPaymentMethodValues((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    splits: Number(value)
                } : item
            )
        );
    }

    function handlePaidChange(index, value) {
        setPaymentMethodValues((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    paid: value
                } : item
            )
        );
    }

    function handleConfirm() {
        const difference = Math.round((totalValue - typedTotalValue) * 100) / 100;

        if (difference > 0) {
            alert(`Valor informado (${typedTotalValue}) é menor que o valor total (${totalValue}).`);
            return;
        }

        if (difference < 0) {
            alert(`Valor informado (${typedTotalValue}) é maior que o valor total (${totalValue}).`);
            return;
        }

        if (paymentMethodValues.find((p) => p.value > 0 && (p.paymentMethod?.id === undefined) && p.paid)) {
            alert(`Informe a forma de pagamento do que já foi pago.`);
            return;
        }

        onConfirm(paymentMethodValues.filter((p) => p.value > 0));
    }

    async function handleDueDateItemChange(index, date) {

        setPaymentMethodValues((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? {
                    ...item,
                    dueDate:
                        getOnlyDate(new Date(date)),
                    paid: getOnlyDate(new Date(date)) <= getOnlyDate(new Date())
                } : item
            )
        );
    }

    function getBackgroundColor(paymentMethodValue) {

        if(paymentMethodValue.value === 0 ) {
            return normalColor;
        }
        if (!paymentMethodValue.paid)
            return attentionColor;

        return normalGreenColor;
    }

    return (
        <ModalDiv closeModal={onClose} style_={{width: '580px'}}>
            <div className="header-label">Formas de Pagamento</div>
            <div className="select-list-container">
                <table style={{width: '665px'}}>
                    <th>
                        Forma de Pagamento
                    </th>
                    <th>
                        {forceDueDate ? 'Pagamento' : 'Vencimento'}
                    </th>
                    <th>
                        Valor
                    </th>
                    {
                        paymentMethodValues.find((p) => p.paymentMethod && p.paymentMethod.allowSplit) &&
                        <th>
                            Parcelas
                        </th>
                    }
                    {/*{*/}
                    {/*    (allowPaymentWhenPaymentDateDifferentDueDate || paymentMethodValues.find((p) => (p.paid || getOnlyDate(new Date(p.dueDate)) <= getOnlyDate(new Date())))) &&*/}
                    {/*    <th>*/}
                    {/*        Pago*/}
                    {/*    </th>*/}
                    {/*}*/}
                    <tbody>

                    {paymentMethodValues.map((paymentMethodValue, index) => (

                        <tr style={{backgroundColor: getBackgroundColor(paymentMethodValue)}}>
                            <td>
                                <select value={paymentMethodValue.paymentMethod && paymentMethodValue.paymentMethod.id}
                                        onChange={(e) => handlePaymentMethodSelected(index, e.target.value)}>
                                    <option value="">Selecione uma Forma de Pagamento</option>
                                    {paymentMethods_.map((paymentMethod, index) => (
                                        <option key={index} value={paymentMethod.id}>
                                            {paymentMethod.name}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td>
                                <DatePicker
                                    disabled={!allowChangeDueDate || forceDueDate}
                                    className="datepicker"
                                    selected={adjustDateToServerTimeZone(new Date(paymentMethodValue.dueDate))}
                                    onChange={(date) => handleDueDateItemChange(index, date)}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </td>
                            <td>
                                <input key={paymentMethodValue.id}
                                       disabled={!allowChangeValue}
                                       style={{width: '80px'}}
                                       type="number" step="0.01" placeholder="0.00"
                                       value={paymentMethodValue.value}
                                       onChange={(e) => handleValueChange(index, e.target.value)}/>
                            </td>

                            {
                                paymentMethodValues.find((p) => p.paymentMethod && p.paymentMethod.allowSplit === true) &&
                                <td>
                                    {paymentMethodValue.paymentMethod && paymentMethodValue.paymentMethod.allowSplit && (
                                        <input type="number"
                                               style={{width: '80px'}}
                                               step="1" placeholder="1"
                                               value={paymentMethodValue.splits}
                                               onChange={(e) => handleSplitsChange(index, e.target.value)}/>

                                    )}
                                </td>
                            }


                            {/*{*/}
                            {/*    (allowPaymentWhenPaymentDateDifferentDueDate || paymentMethodValues.find((p) => (paymentMethodValue.paid || getOnlyDate(new Date(p.dueDate)) <= getOnlyDate(new Date())))) &&*/}
                            {/*    <td>*/}

                            {/*        {*/}
                            {/*            (allowPaymentWhenPaymentDateDifferentDueDate || paymentMethodValue.paid || getOnlyDate(new Date(paymentMethodValue.dueDate)) <= getOnlyDate(new Date())) &&*/}
                            {/*        <input type="checkbox"*/}
                            {/*               checked={paymentMethodValue.paid}*/}
                            {/*               onChange={(e) => handlePaidChange(index, e.target.checked)}/>*/}

                            {/*        }*/}
                            {/*    </td>*/}
                            {/*}*/}

                        </tr>
                    ))}

                    </tbody>
                </table>
            </div>
            <div className="total-label"
                 style={{color: normalGreenColor, height: '37.6px', display: 'flex', alignItems: "center"}}>
                Valor Pago: R$ {typedTotalValuePaid.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}
            </div>
            <div className="total-label"
                 style={{color: attentionColor, height: '37.6px', display: 'flex', alignItems: "center"}}>
                Valor Restante: R$ {(typedTotalValuePending).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}
            </div>
            <ActionButtonsManagerView>
                <ConfirmButton onClick={handleConfirm}/>
            </ActionButtonsManagerView>
        </ModalDiv>
    );
};

export default PaymentMethodValueSelect;
